(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var embeddedLoginWindow = null;

window.onmessage = function (e) {
  if (e.data.hasOwnProperty("frameHeight")) {
    document.getElementById("embedded-donation-frame").style.height = e.data.frameHeight + 30 + "px";
  }

  if (e.data.hasOwnProperty("requestLogin")) {
    if (embeddedLoginWindow === null || embeddedLoginWindow.closed) {
      embeddedLoginWindow = window.open(e.data.requestLogin, 'embeddedLoginWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500');
    } else {
      embeddedLoginWindow.focus();
    }
  }

  if (e.data.hasOwnProperty("gotoFrameTop")) {
    gotoEmbeddedDonationFrame();
  }

  if (e.data.hasOwnProperty("reloadUser")) {
    document.getElementById("embedded-donation-frame").contentWindow.postMessage({ reloadUser: true }, '*');
  }
};

function gotoEmbeddedDonationFrame() {
  var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

  if (!offset) {
    offset = 120;
  }

  var element = document.getElementById("embedded-donation-frame");
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}

var gotoDonationTargets = '.goto-donation';
if (location.host.toLowerCase().includes('modernday.')) {
  gotoDonationTargets += ', a[href*="#donation-section"], a[href*="' + window.location.host + '/donate"]';
}
window.addEventListener('DOMContentLoaded', function (event) {
  var eventTargets = document.querySelectorAll(gotoDonationTargets);
  if (eventTargets && eventTargets.length) {
    for (var i = 0; i < eventTargets.length; i++) {
      eventTargets[i].addEventListener('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        gotoEmbeddedDonationFrame(200);
      });
    }
  }
});

},{}]},{},[1]);
